<template>
    <page title="Conta">
        <v-breadcrumbs slot="breadcrumb" class="pa-0">
            <v-breadcrumbs-item exact :to="{name:'dashboard'}">Início</v-breadcrumbs-item>
            <v-breadcrumbs-divider>></v-breadcrumbs-divider>
            <v-breadcrumbs-item exact :to="{name:'financeiro'}">Financeiro</v-breadcrumbs-item>
        </v-breadcrumbs>

        <div slot="menu" >
            <v-menu offset-y v-if="$parent.$parent.usuario.admin" >
                <template v-slot:activator="{ on }">
                    <v-btn outlined style="border: solid thin rgba(0, 0, 0, 0.12)" v-on="on">
                        <v-icon left>mdi-cog</v-icon>
                        <span>Ações</span>
                        <v-icon right>mdi-chevron-down</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item :disabled="uploadingOfx" @click="onUploadArquivoButtonClicked">
                        <v-list-item-title>Enviar Arquivo OFX</v-list-item-title>
                    </v-list-item>
                    <v-list-item :disabled="uploadingOfx" @click="baixarArquivoOfx">
                        <v-list-item-title>Baixar Arquivo OFX</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
<!--            <v-btn class="ml-2" text outlined :to="{name:'remessas'}">Remessas</v-btn>
            <v-btn class="ml-2" text outlined :to="{name:'retornos'}">Retornos</v-btn>-->
            <input v-show="false" ref="inputFileUpload" type="file"
                   @input="uploadOfx" >
        </div>
        <v-progress-linear v-if="uploadingOfx" indeterminate />
        <v-tabs class="mb-3 custom-tabs" v-model="contaSelecionada" v-if="$parent.$parent.usuario.admin" >
            <v-tab>Conta Corrente</v-tab>
            <v-tab>Conta Poupança</v-tab>
            <v-tab>Conta Investimento</v-tab>
        </v-tabs>
        <div class="" v-if="contaInfo">
            <v-alert class="mb-0 py-0" text color="teal">
                <v-row class="align-baseline">
                    <v-col class="text-left">
                        <span class="text-h6" style="line-height: unset">Saldo <span class="caption">(Última atualização {{ultimoProcessamentoPorConta | moment("DD/MM [às] HH:mm:ss")}})</span></span>
                    </v-col>
                    <v-col class="text-right">
                        <span class="text-h4">
                            {{saldoPorConta | formatNumber}}
                        </span>
                    </v-col>
                </v-row>
            </v-alert>
        </div>

        <v-row v-if="lancamentosSemMovimentacao.length > 0">
            <v-col>
                <v-card outlined style="border-color: #c20000" >
                    <v-card-title class="pb-0 red--text">
                        {{lancamentosSemMovimentacao.length}} Lançamentos sem movimentação para serem corrigidos
                    </v-card-title>
                    <v-card-text class="pa-0 ">
                        <v-simple-table class="table-lancamentos-sem-movimentos" dense fixed-header>
                            <template v-slot:default>
                                <thead>
                                <tr style="white-space: nowrap">
                                    <th class="text-left">Data</th>
                                    <th class="text-left">Descrição</th>
                                    <th class="text-left">Dcto.</th>
                                    <th class="text-left" >Valor</th>
                                    <th class="text-left"></th>
                                </tr>
                                </thead>
                                <tbody>
                                <template v-for="(transacao, index) in lancamentosSemMovimentacao">
                                    <tr :key="transacao.id" :class="transacao.ignored ? 'ignored' : ''" style="white-space: nowrap">
                                        <td class="grey--text darken-3 caption">
                                            <span>{{ transacao.dtposted | moment("DD/MM") }}</span>
                                        </td>
                                        <td><span>{{transacao.memo}}</span>
                                        </td>
                                        <td>
                                            <template v-if="criaLinkReferencia(transacao)">
                                                <!--                                        <router-link :to="criaLinkReferencia(transacao)">{{transacao.checknum}}</router-link>-->
                                                <a href="" @click.prevent="viewMovimentacao(transacao.movimentacaoPagamento.movimentacao.id)">
                                                    {{transacao.checknum}}
                                                </a>
                                                <v-btn v-if="!transacao.ignored" @click="desvincularMovimentacao(transacao)"
                                                       icon x-small class="ml-1">
                                                    <v-icon color="red" small>mdi-close</v-icon>
                                                </v-btn>
                                            </template>

                                            <template v-else>
                                                <span>{{transacao.checknum}}</span>
                                                <v-btn v-if="!transacao.ignored" @click="vincularMovimentacao(transacao)"
                                                       icon x-small class="ml-1">
                                                    <v-icon color="primary" small>mdi-magnify</v-icon>
                                                </v-btn>
                                            </template>

                                        </td>
                                        <td :class="transacao.trnamt > 0 ? 'indigo--text' : 'red--text'">
                                            <span>{{transacao.trnamt | formatNumber}}</span>
                                        </td>
                                        <td>
                                            <v-btn v-if="!transacao.ignored" title="Ignorar Transação"
                                                   @click="ignorarTransacao(transacao, true)"
                                                   icon x-small class="ml-1">
                                                <v-icon color="red" small>mdi-cancel</v-icon>
                                            </v-btn>
                                        </td>
                                    </tr>
                                </template>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col sm="7" cols="12">
                <v-card outlined min-height="320">
                    <v-card-title class="pb-0">
                        <span class="mr-2">Transações</span>
                    </v-card-title>
                    <v-card-text class="pa-0">
                        <v-chip-group v-model="tipoLancamentosSelecionado" mandatory class="ml-4" color="primary" >
                            <v-chip small outlined :value="1" v-if="lancamentosFuturos.length > 0">Lançamentos Futuros</v-chip>
                            <v-chip small outlined :value="2" v-if="ultimosLancamentos.length > 0">Últimos Lançamentos</v-chip>
                            <v-chip small outlined :value="-1" :to="{name:'conta_lancamentos'}">Ver Tudo<v-icon right small>mdi-chevron-right</v-icon></v-chip>
                        </v-chip-group>
                        <v-simple-table dense>
                            <template v-slot:default>
                                <thead>
                                <tr style="white-space: nowrap">
                                    <th class="text-left">Data</th>
                                    <th class="text-left">Descrição</th>
                                    <!--                                    <th class="text-left">Dcto.</th>-->
                                    <th class="text-right" >Valor</th>
                                </tr>
                                </thead>
                                <tbody>
                                <template v-for="(transacao, index) in lancamentosPorTipo">
                                    <tr :key="transacao.id" :class="transacao.ignored ? 'ignored' : ''" style="white-space: nowrap">
                                        <td class="grey--text darken-3 caption">
                                            <span>{{ transacao.dtposted | moment("DD/MM") }}</span>
                                        </td>
                                        <td class="caption" style="width: 100%"><span>{{transacao.memo}}</span></td>
                                        <!--                                        <td class="caption"><span>{{transacao.checknum}}</span></td>-->
                                        <td :class="(transacao.trnamt > 0 ? 'indigo--text' : 'red--text') + ' caption text-right'">
                                            <span>{{transacao.trnamt | formatNumber}}</span>
                                        </td>
                                    </tr>
                                </template>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col sm="5" cols="12">
                <v-card outlined >
                    <v-card-title class="">
                        <span class="mr-2">Transmissões</span>
                    </v-card-title>
                    <v-card-text class="pa-0">
                        <v-expansion-panels accordion flat tile >
                            <v-expansion-panel>
<!--                                <v-expansion-panel-header>Últimas Remessas</v-expansion-panel-header>-->
                                <v-expansion-panel-header color="blue-grey lighten-5">
                                    <template v-slot:default="{ open }">
                                        <v-row no-gutters>
                                            <v-col class="shrink text-no-wrap pt-1">Últimas Remessas</v-col>
                                            <v-col class="text--secondary">
                                                <v-fade-transition leave-absolute>
                                                    <v-btn v-if="open" class="ml-8"
                                                           rounded color="white" x-small
                                                    :to="{name:'remessas'}">Ver mais</v-btn>
                                                </v-fade-transition>
                                            </v-col>
                                        </v-row>
                                    </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-list dense class="mx-n6 mb-n4 py-0" v-if="ultimasRemessas.length > 0">
                                        <template v-for="remessa in ultimasRemessas">
                                            <v-list-item :to="{ name: 'remessa_view', params: { id: parseInt(remessa.id) }}">
                                                <v-list-item-content>
                                                    <v-list-item-title class="font-weight-light"><b>{{ remessa.dataEnvioArquivo | moment("DD/MM") }}</b> - Referente a {{remessa.totalTitulos}} títulos</v-list-item-title>
                                                </v-list-item-content>
                                                <v-list-item-action class="my-0">
                                                    <v-list-item-action-text>
                                                        <b>{{remessa.valorTotal | formatNumber}}</b>
                                                    </v-list-item-action-text>
                                                </v-list-item-action>
                                            </v-list-item>
                                            <v-divider ></v-divider>
                                        </template>
                                    </v-list>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header color="blue-grey lighten-5">
                                    <template v-slot:default="{ open }">
                                        <v-row no-gutters>
                                            <v-col class="shrink text-no-wrap pt-1">Últimos Retornos</v-col>
                                            <v-col >
                                                <v-fade-transition leave-absolute>
                                                    <v-btn v-if="open" class="ml-4"
                                                           rounded color="white" x-small
                                                           :to="{name:'retornos'}">Ver mais</v-btn>
                                                </v-fade-transition>
                                            </v-col>
                                        </v-row>
                                    </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-list dense class="mx-n6 py-0" v-if="ultimosRetornos.length > 0">
                                        <template v-for="retorno in ultimosRetornos">
                                            <v-list-item :to="{ name: 'retorno_view', params: { id: parseInt(retorno.id) }}">
                                                <v-list-item-content>
                                                    <v-list-item-title class="font-weight-regular">{{retorno.totalTransacoes}} transações</v-list-item-title>
                                                    <v-list-item-subtitle>
                                                        <v-chip label color="light-blue darken-3" dark x-small class="text-uppercase px-1 mr-1"  v-if="retorno.totalLiquidacoesNormais > 0">
                                                            {{retorno.totalLiquidacoesNormais}} Liquidadas
                                                        </v-chip>
                                                        <v-chip label color="green" dark x-small class="text-uppercase px-1 mr-1"  v-if="retorno.totalEntradasConfirmadas > 0">
                                                            {{retorno.totalEntradasConfirmadas}} Entradas Confirmadas
                                                        </v-chip>
                                                        <v-chip label color="red" dark x-small class="text-uppercase px-1 mr-1"  v-if="retorno.totalEntradasRejeitadas > 0">
                                                            {{retorno.totalEntradasRejeitadas}} Entradas Rejeitadas
                                                        </v-chip>
                                                        <v-chip label color="blue-grey darken-2" dark x-small class="text-uppercase px-1"  v-if="retorno.totalDesagendamentoDoDebitoAutomatico > 0">
                                                            {{retorno.totalDesagendamentoDoDebitoAutomatico}} Desagendadas
                                                        </v-chip>
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                                <v-list-item-action class="my-1">
                                                    <v-list-item-action-text>
                                                        {{retorno.dataGravacaoArquivo  | moment("DD MMM YYYY")}}
                                                    </v-list-item-action-text>
                                                    <v-list-item-action-text>
                                                        <b>{{retorno.valorTotalPago | formatNumber}}</b>
                                                    </v-list-item-action-text>
                                                </v-list-item-action>
                                            </v-list-item>
                                            <v-divider ></v-divider>
                                        </template>
                                    </v-list>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card-text>
                </v-card>
<!--                <v-card flat class="mb-6 " color="teal" dark>
                    <v-card-title class="pb-0">
                        <span class="mr-2">Lançamentos Futuros</span>
                        <v-btn @click="" :to="{name:'conta_lancamentos_futuros'}" x-small color="white" light rounded>Mais Detalhes</v-btn>
                    </v-card-title>
                    <v-card-text class="pa-0">
                        <v-simple-table dense class="table-lancamentos-futuros" v-if="lancamentosFuturos.length > 0">
                            <template v-slot:default>
                                <thead>
                                <tr style="white-space: nowrap">
                                    <th class="text-left">Data</th>
                                    <th class="text-left">Lançamento</th>
                                    &lt;!&ndash;                                    <th class="text-left">Dcto.</th>&ndash;&gt;
                                    <th class="text-left" >Valor</th>
                                </tr>
                                </thead>
                                <tbody>
                                <template v-for="(transacao, index) in lancamentosFuturos">
                                    <tr :key="transacao.id" :class="transacao.ignored ? 'ignored' : ''" style="white-space: nowrap">
                                        <td class="caption">
                                            <span>{{ transacao.dtposted | moment("DD/MM") }}</span>
                                        </td>
                                        <td class="caption"><span>{{transacao.memo}}</span></td>
                                        &lt;!&ndash;                                        <td class="caption"><span>{{transacao.checknum}}</span></td>&ndash;&gt;
                                        <td :class="(transacao.trnamt > 0 ? 'indigo&#45;&#45;text' : 'red&#45;&#45;text') + ' caption'">
                                            <span>{{transacao.trnamt | formatNumber}}</span>
                                        </td>
                                    </tr>
                                </template>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <div v-else-if="loading === false && (lancamentosFuturos === null || lancamentosFuturos.length === 0)" class="text-center pa-6">
                            <span class="body-1 font-weight-light">Nenhum lançamento!</span>
                        </div>
                    </v-card-text>
                </v-card>-->
            </v-col>
        </v-row>

        <vincular-movimentacao-dialog ref="vincularMovimentacaoDialog" @saved="onMovimentavaoVinculada"/>
        <view-movimentacao-dialog ref="viewMovimentacaoDialog" />
        <criar-movimentacao-dialog ref="criarMovimentacaoDialog" @saved="onMovimentacaoSaved" />
    </page>
</template>

<script>
import page from "@/components/Page";
import gql from "graphql-tag";
import {getExtensionFile} from "@/assets/FileUtils";
import ViewMovimentacaoDialog from "@/views/financeiro/ViewMovimentacaoDialog";
import VincularMovimentacaoDialog from "@/views/financeiro/conta/VincularMovimentacaoDialog";
import CriarMovimentacaoDialog from "@/views/financeiro/conta/CriarMovimentacaoDialog";

export default {
    name: "View",
    components: {
        page,
        ViewMovimentacaoDialog,
        VincularMovimentacaoDialog,
        CriarMovimentacaoDialog,
    },
    computed:{
        saldoPorConta(){
            switch (this.contaSelecionada){
                case 0:
                    return this.contaInfo.saldoContaCorrente;
                case 1:
                    return this.contaInfo.saldoContaPoupanca;
                case 2:
                    return this.contaInfo.saldoContaInvestimento;
                default:
                    return 0;
            }
        },
        ultimoProcessamentoPorConta(){
            switch (this.contaSelecionada){
                case 0:
                    return this.contaInfo.ultimoProcessamentoContaCorrente;
                case 1:
                    return this.contaInfo.ultimoProcessamentoContaPoupanca;
                case 2:
                    return this.contaInfo.ultimoProcessamentoContaInvestimento;
                default:
                    return null;
            }
        },
        lancamentosPorTipo(){
            if(!this.tipoLancamentosSelecionado){
                return []
            }
            if(this.tipoLancamentosSelecionado === 1){
                return this.lancamentosFuturos;
            }

            if(this.tipoLancamentosSelecionado === 2){
                return this.ultimosLancamentos;
            }
        },
    },
    watch: {
        contaSelecionada:{
            immediate: false,
            handler(value){
                this.$router.replace({query: {tipo:value}})
                this.getLancamentos(value+1)
            }
        }
    },
    data() {
        return {
            loading: false,
            uploadingOfx: false,
            contaSelecionada: null,
            contaInfo: null,
            ultimosLancamentos: [],
            lancamentosFuturos: [],
            lancamentosSemMovimentacao: [],
            tipoLancamentosSelecionado: null,
            ultimasRemessas: [],
            ultimosRetornos: [],
        }
    },
    mounted() {
        document.title = "Conta - JF Assessoria Rural"
        this.getContaInfo();
        this.getUltimasRemessasERetornos();
        if(this.$route.query.tipo === undefined){
            this.contaSelecionada = 0;
        }else {
            this.contaSelecionada = parseInt(this.$route.query.tipo)
        }
    },
    methods: {
        getContaInfo(){
            this.$apollo.query({
                query : gql`query {
                    contaInfo{
                        saldoContaCorrente
                        saldoContaPoupanca
                        saldoContaInvestimento
                        ultimoProcessamentoContaCorrente
                        ultimoProcessamentoContaPoupanca
                        ultimoProcessamentoContaInvestimento
                    }
                }`,
            }).then((result) => {
                this.contaInfo = result.data.contaInfo;
            })
        },
        getLancamentos(conta){
            this.$apollo.query({
                query : gql`query($conta:Int) {
                    lastestOfxTransactions(conta: $conta){
                        id
                        dtposted
                        memo
                        trnamt
                        trntype
                        checknum
                        conta
                        ignored
                    }
                    futureOfxTransactions(conta: $conta){
                        id
                        dtposted
                        memo
                        trnamt
                        trntype
                        checknum
                        conta
                        ignored
                    }
                    ofxTransactionsSemMovimentacao(conta: $conta){
                        id
                        dtposted
                        memo
                        trnamt
                        trntype
                        checknum
                        conta
                        ignored
                    }
                }`,
                variables: {
                    conta
                }
            }).then((result) => {
                this.ultimosLancamentos = result.data.lastestOfxTransactions.reverse();
                this.lancamentosFuturos = result.data.futureOfxTransactions.reverse();
                this.lancamentosSemMovimentacao = result.data.ofxTransactionsSemMovimentacao.reverse();

                if(this.lancamentosFuturos.length > 0){
                    this.tipoLancamentosSelecionado = 1
                }
            })
        },
        getUltimasRemessasERetornos(){
            this.$apollo.query({
                query : gql`query {
                    ultimasRemessas{
                        id
                        valorTotal
                        totalTitulos
                        dataEnvioArquivo
                    }
                    ultimosRetornos{
                        id
                        numeroAvisoBancario
                        dataGravacaoArquivo
                        totalTransacoes
                        valorTotalPago
                        totalLiquidacoesNormais
                        totalEntradasConfirmadas
                        totalEntradasRejeitadas
                        totalDesagendamentoDoDebitoAutomatico
                        createdAt
                    }
                }`,
            }).then((result) => {
                this.ultimasRemessas = result.data.ultimasRemessas
                this.ultimosRetornos = result.data.ultimosRetornos
            })
        },
        criaLinkReferencia(transacao){
            if(transacao.movimentacaoPagamento){
                return {path: 'movimentacao/' + transacao.movimentacaoPagamento.movimentacao.id}
            }
            return null
        },
        desvincularMovimentacao(transacao){
            this.$dialog.confirm({
                text: 'Tem certeza que deseja desvincular a movimentação dessa transação?',
                title: 'Atenção',
                actions: {
                    false: 'Cancelar', yes: {text: 'Sim', color: 'primary',},
                }
            }).then((result) => {
                if(result){
                    this.$apollo.mutate({
                        mutation: gql`mutation($transacaoId: Int!) {
                            removeMovimentacaoDaTransacao(transacaoId: $transacaoId)
                        }`,
                        variables: {
                            transacaoId: transacao.id
                        },
                    }).then((result) => {
                        if(result.data.removeMovimentacaoDaTransacao){
                            transacao.movimentacaoPagamento = null;
                        }
                    })
                }

            })
        },
        vincularMovimentacao(transacao){
            this.$refs.criarMovimentacaoDialog.openDialog(transacao)
            //this.$refs.vincularMovimentacaoDialog.openDialog(transacao)
        },
        onMovimentavaoVinculada(movimentacao){
            this.getLancamentos(this.contaSelecionada+1)
        },
        ignorarTransacao(transacao, valor){
            this.$apollo.mutate({
                mutation: gql`mutation($id: Int!, $valor: Boolean!) {
                    ignorarTransacao(id: $id, valor: $valor)
                }`,
                variables: {
                    id: transacao.id,
                    valor: valor
                },
            }).then((result) => {
                if(result.data.ignorarTransacao){
                    transacao.ignored = valor;
                    this.getLancamentos(this.contaSelecionada+1)
                }
            })
        },
        viewMovimentacao(id){
            this.$refs.viewMovimentacaoDialog.openDialog(id);
        },
        onMovimentacaoSaved(movimentacao){
            this.onMovimentavaoVinculada(movimentacao)
        },
        onUploadArquivoButtonClicked(){
            this.$refs.inputFileUpload.value = "";
            this.$refs.inputFileUpload.click();
        },
        uploadOfx(e){
            let file = e.target.files[0]
            let ext = getExtensionFile(file.name)
            if(ext !== "ofx"){
                this.$dialog.error({
                    title:'Formato inválido',
                    text: 'Selecione um arquivo .ofx para poder continuar',
                    actions:{ok:'ok'}})
                return;
            }
            this.uploadingOfx = true;

            const formData = new FormData();
            formData.append('file', file);
            this.$axios.post('/contas/upload_ofx', formData, {
                headers: {
                    // @ts-ignore
                    'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                },
            }).then((result) => {
                if(result.status === 200){
                    if(result.data > 0){
                        this.$notify({text: result.data + ' registros inseridos!', type: 'info'});
                        //this.getList(this.currentFilter);
                        this.getContaInfo();
                        this.getLancamentos(this.contaSelecionada+1)
                    }else{
                        this.$dialog.error({title: 'Ops!', text: 'Nenhum registro novo encontrado!', actions: [{text: 'ok'}]})
                    }
                }
            }).catch((error) => {
                this.$notify({text: 'Falha ao enviar o arquivo!', type: 'error'});
            })
            .finally(()=>{
                this.uploadingOfx = false
            })
        },
        baixarArquivoOfx(){
            this.$apollo.mutate({
                mutation: gql`mutation {
                    iniciaTarefaBaixaExtratoBancario{
                        id
                    }
                }`,
            })
        },
    }
}
</script>
<style>
    .table-lancamentos-sem-movimentos > div{
        max-height: 200px;
    }
    .custom-tabs .v-slide-group__prev{
        display: none !important;
    }
</style>
<style scoped>

    .table-lancamentos-sem-movimentos,
    .table-lancamentos-futuros{
        background-color: transparent !important;
    }
    .table-lancamentos-futuros thead th,
    .table-lancamentos-futuros tfoot td{
        background-color: #00897B !important;
    }
    .table-lancamentos-futuros tfoot td{
        position: sticky;
        z-index: 2;
    }
    .table-lancamentos-futuros tfoot .foot-total td{
        bottom: 32px;
    }
    .table-lancamentos-futuros tfoot .foot-nao-recebidos td{
        bottom: 0;
    }
    .table-lancamentos-futuros tbody tr:hover{
        background-color: #26A69A !important;
    }

    .v-data-table tr.ignored{
        opacity: 0.5;
        background: #eeee
    }

    .v-data-table tr.ignored td>span{
        text-decoration: line-through;
    }

    .v-data-table tr.ignored:hover{
    }
</style>